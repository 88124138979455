<template>
  <div>
    <el-dialog title="新增考勤组" :visible.sync="createDialog" width="1000px">
      <div style="margin:0 30px;height: 440px;overflow: auto">
        <el-form label-position="top" :model="attendanceGroup" :rules="rules" ref="formRef" label-width="110px" class="demo-ruleForm">
          <el-row>
            <el-col :span="8">
              <el-form-item label-width="100px" label="考勤组名称" prop="name">
                <el-input v-model="attendanceGroup.name" style="width: 210px" placeholder="请输入"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label-width="100px" label="考勤人员" prop="changePeople">
                <el-cascader collapse-tags style="width: 210px" ref="cascaderRef"
                    :options="deptUserTree" v-model="attendanceGroup.changePeople"
                    :props="{ multiple: true,label:'name',value:'id'}"></el-cascader>
              </el-form-item>
            </el-col>

          </el-row>
          <el-row>
            <el-col :span="8">
              <el-form-item label-width="100px" label="考勤设置" prop="fixedShifts">
                <button v-if="attendanceGroup.attendanceType" style="color: #606266;justify-content: space-between" class="set-button" @click="attendanceTimeDialog = true">
                  <div>{{attendanceTypeName(Number(attendanceGroup.attendanceType))}}</div>
                  <div class="el-icon-arrow-right"></div>
                </button>
                <button v-else class="set-button" @click="attendanceTimeDialog = true" style="justify-content: space-between">
                  <div>去设置</div>
                  <div class="el-icon-arrow-right"></div>
                </button>
              </el-form-item>
              <el-dialog width="940px" title="考勤设置" :visible.sync="attendanceTimeDialog" append-to-body>
                <attendance-time ref="attendanceTime"/>
                <span slot="footer" class="dialog-footer">
                <el-button @click="attendanceTimeDialog = false">取 消</el-button>
                <el-button type="danger" @click="getAttendanceTime">确 定</el-button>
              </span>
              </el-dialog>
            </el-col>
            <el-col :span="8">
              <el-form-item label-width="100px" label="打卡设置" prop="clockVerify" :required="true">
                <button class="set-button" @click="clockTypeDialog = true">
                  <el-checkbox v-model="isAddress" style="width: 32px" label="位置"></el-checkbox>
                  <el-checkbox v-model="isWiFi" style="width: 32px" label="WiFi"></el-checkbox>
                  <el-checkbox v-model="isBluetooth" style="width: 32px" label="蓝牙"></el-checkbox>
                </button>
              </el-form-item>
              <el-dialog width="900px" title="打卡设置" :visible.sync="clockTypeDialog" append-to-body>
                <clock-type ref="clockType"/>
                <span slot="footer" class="dialog-footer">
                <el-button @click="clockTypeDialog = false">取 消</el-button>
                <el-button type="danger" @click="getClockType">确 定</el-button>
              </span>
              </el-dialog>
            </el-col>
            <el-col :span="8">
              <el-form-item label-width="100px" label="补卡规则" prop="attendanceType">
                <el-select v-model="attendanceGroup.cardReplacementRulesId" filterable placeholder="休息">
                  <el-option v-for="item in repairCardList" :key="item.id" :label="item.name" :value="item.id"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="8">
              <el-form-item label-width="100px" label="拍照打卡" prop="attendanceType">
                <button class="set-button">
                  <el-switch disabled
                      v-model="attendanceGroup.clockPhotograph"
                      active-color="#ff4d4f"  :active-value="1"
                      inactive-color="#C0C4CC" :inactive-value="0"
                  ></el-switch>
                </button>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label-width="100px" label="人脸识别" prop="attendanceType">
                <button class="set-button">
                  <el-switch disabled
                      v-model="attendanceGroup.clockAuthentication"
                      active-color="#ff4d4f"  :active-value="1"
                      inactive-color="#C0C4CC" :inactive-value="0"
                  ></el-switch>
                </button>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label-width="100px" label="外勤打卡">
                <el-popover placement="left" width="200"
                            :trigger="attendanceGroup.allowOutworkClock == 1 ? 'hover' : 'focus'"
                            :key="attendanceGroup.allowOutworkClock">
                  <div>
                    <div>
                      <el-checkbox style="margin: 4px 6px 4px 0" v-model="attendanceGroup.outworkApprove" :true-label="1" :false-label="0"></el-checkbox>外勤打卡需审批
                    </div>
                    <div>
                      <el-checkbox style="margin: 4px 6px 4px 0" v-model="attendanceGroup.outworkPictures" :true-label="1" :false-label="0"></el-checkbox>外勤打卡需拍照
                    </div>
                    <div>
                      <el-checkbox style="margin: 4px 6px 4px 0" v-model="attendanceGroup.outworkNotes" :true-label="1" :false-label="0"></el-checkbox>外勤打卡需填写备注
                    </div>
                    <div>
                      <el-checkbox style="margin: 4px 6px 4px 0" v-model="attendanceGroup.clockInUpdate" :true-label="1" :false-label="0"></el-checkbox>上班允许内勤卡更新外勤卡
                    </div>
                    <div>
                      <el-checkbox style="margin: 4px 6px 4px 0" v-model="attendanceGroup.clockOffUpdate" :true-label="1" :false-label="0"></el-checkbox>下班允许外勤卡更新内勤卡
                    </div>
                  </div>
                  <template #reference>
                    <button class="set-button">
                      <el-switch
                          v-model="attendanceGroup.allowOutworkClock"
                          active-color="#ff4d4f"  :active-value="1"
                          inactive-color="#C0C4CC" :inactive-value="0"
                      ></el-switch>
                    </button>
                  </template>
                </el-popover>
              </el-form-item>
            </el-col>
          </el-row>
          <div class="day-off">
            <div style="font-size: 15px;font-weight: bold;margin-bottom: 16px">休息日考勤设置</div>
            <el-row style="margin-left: 20px">
              <el-col :span="12">
                <el-form-item label-width="100px" label="上班打卡" prop="attendanceType">
                  <button style="color: #606266;padding-left: 8px;width: 85%" class="set-button">
                    早上<el-time-picker
                        format="HH:mm"
                        value-format="HH:mm"
                        size="mini" style="width: 100px;margin:0 10px"
                        v-model="attendanceGroup.restBeginClock" placeholder="请选择">
                    </el-time-picker>后可打卡
                  </button>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row style="margin-left: 20px">
              <el-col :span="12">
                <el-form-item label-width="100px" label="上班打卡间隔" prop="attendanceType">
                  <button style="color: #606266;padding-left: 8px;width: 85%" class="set-button">
                    上班打卡后 <el-input-number style="width: 100px;margin: 0 10px" size="mini" v-model="attendanceGroup.restToClockInterval"></el-input-number>
                    分钟，可再次打卡
                  </button>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label-width="100px" label="下班打卡间隔" prop="attendanceType">
                  <button style="color: #606266;padding-left: 8px;width: 85%" class="set-button">
                    下班打卡后 <el-input-number style="width: 100px;margin: 0 10px" size="mini" v-model="attendanceGroup.restOffClockInterval"></el-input-number>
                    分钟，可再次打卡
                  </button>
                </el-form-item>
              </el-col>
            </el-row>
          </div>
        </el-form>
      </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="createDialog = false">取 消</el-button>
          <el-button type="danger" @click="createdAttendanceGroup">确 定</el-button>
        </span>
    </el-dialog>
  </div>
</template>

<script>
import AttendanceTime from "./formComponents/AttendanceTime";
import {selectRepairCardPage} from '/src/api/attendance/attendanceRules';
import {selectDeptAndUserTree} from '/src/api/contacts/dept';
import { attendanceTypeName } from "/src/utils/constant/stateConstant";
import ClockType from "./formComponents/ClockType";
import {createdAttendanceGroup} from '/src/api/attendance/attendanceGroup'
export default {
  name: "CreatedAttendanceGroup",
  components:{
    AttendanceTime,ClockType
  },
  data() {
    const changePeople = (rule, value, callback) => {
      const form = this.attendanceGroup;
      if (form.changePeople && form.changePeople.length>0){
        callback();return
      }
      callback(new Error('请选中考勤人员！'));
    };
    // 自定义验证规则
    const clockVerify = (rule, value, callback) => {
      const form = this.attendanceGroup;
      if (form.attendanceClockMethodsWifi.length>0){
        callback();return
      }
      if (form.attendanceClockMethodsAddress.length>0){
        callback();return
      }
      if (form.attendanceClockMethodsBluetooth.length>0) {
        callback();return
      }
      callback(new Error('请添加一种打卡方式！'));
    };
    return {
      attendanceGroup: {
        name:"",
        restBeginClock:"05:00",
        restToClockInterval:0,
        restOffClockInterval:0,
        restClockType:1,
        attendanceType:0,
        allowOutworkClock:0,
        attendanceGroupPeople:[],
        changePeople:[],
        outworkApprove:0,
        outworkNotes:0,
        classesId:null,
        outworkPictures:0,
        clockPhotograph:1,
        clockAuthentication:1,
        cardReplacementRulesId:1000,
        clockInUpdate:0,
        clockOffUpdate:0,
        fixedShifts:[],
        fixedShiftsClock:[],
        fixedShiftsNoClock:[],
        noSchedulingClock:0,
        fixedShiftsScheduling:[],
        attendanceClockMethodsWifi:[],
        attendanceClockMethodsAddress:[],
        attendanceClockMethodsBluetooth:[],
        clockVerify:true,//打卡方式表单验证临时字段
      },
      deptUserTree:[],
      repairCardList:[],
      createDialog:false,
      attendanceTimeDialog:false,
      clockTypeDialog:false,
      rules: {
        name: [{required: true, message: "请输入名称！", trigger: "blur"}],
        attendanceType:[{required: true, message: "请设置考勤时间！", trigger: "blur"}],
        fixedShifts:[{required: true, message: "请设置考勤规则！", trigger: "blur"}],
        changePeople: [{ validator: changePeople, trigger: 'blur' }],//打卡设置的自定义表单验证
        clockVerify: [{ validator: clockVerify, trigger: 'blur' }],//打卡设置的自定义表单验证
      }
    }
  },
  computed:{
    isWiFi(){
      return this.attendanceGroup.attendanceClockMethodsWifi.length>0 ? true : false;
    },
    isAddress(){
      return this.attendanceGroup.attendanceClockMethodsAddress.length>0 ? true : false;
    },
    isBluetooth(){
      return this.attendanceGroup.attendanceClockMethodsBluetooth.length>0 ? true : false;
    }
  },
  methods: {
    changePeople(){
      let list = [];
      const ref = this.$refs.cascaderRef;
      const checkedNodes = ref.getCheckedNodes();
      checkedNodes.forEach((node) => {
        list.push({coachId: node.value,coachName:node.label})
      });
      this.attendanceGroup.attendanceGroupPeople = list;
    },
    attendanceTypeName,
    /**
     * 调用子组件 考勤设置 的表单验证
     */
    getAttendanceTime(){
      let ref = this.$refs.attendanceTime;
      ref.$refs.formRef.validate((valid) => {
        if (!valid){return}
        let item = ref.attendanceTime;
        this.attendanceTimeDialog = false;
        this.attendanceGroup.attendanceType = item.attendanceType;
        this.attendanceGroup.fixedShifts = item.fixedShifts;
        this.attendanceGroup.fixedShiftsClock = item.fixedShiftsClock;
        this.attendanceGroup.noSchedulingClock = item.noSchedulingClock;
        this.attendanceGroup.fixedShiftsNoClock = item.fixedShiftsNoClock;
        this.attendanceGroup.fixedShiftsScheduling = item.fixedShiftsScheduling;
      })
    },
    /**
     * 调用子组件 打卡方式 的表单验证
     */
    getClockType(){
      let ref = this.$refs.clockType;
      ref.$refs.formRef.validate((valid) => {
        if (!valid){return}
          let item = ref.clockType;
          this.clockTypeDialog = false;
          this.attendanceGroup.attendanceClockMethodsWifi = item.attendanceClockMethodsWifi;
          this.attendanceGroup.attendanceClockMethodsAddress = item.attendanceClockMethodsAddress;
          this.attendanceGroup.attendanceClockMethodsBluetooth = item.attendanceClockMethodsBluetooth;
      })
    },
    createdAttendanceGroup(){
      this.$refs.formRef.validate((valid) => {
        if (valid) {
          this.changePeople();
          createdAttendanceGroup(this.attendanceGroup).then(val=>{
            if (val.status){
              this.defaultData();
              this.$emit('refresh');
              this.createDialog = false;
              this.$message({message: '新增成功！', type: 'success'});
            }
          })
        }
      })
    },
    openDialog() {
      this.createDialog = true;
    },
    defaultData(){
      this.attendanceGroup = {
        name:"",
        restBeginClock:"05:00",
        restToClockInterval:0,
        restOffClockInterval:0,
        restClockType:1,
        attendanceType:0,
        allowOutworkClock:0,
        attendanceGroupPeople:[{coachId:1999,coachName:"李昕"}],
        outworkApprove:0,
        outworkNotes:0,
        classesId:null,
        outworkPictures:0,
        clockPhotograph:1,
        clockAuthentication:1,
        cardReplacementRulesId:1000,
        clockInUpdate:0,
        clockOffUpdate:0,
        fixedShifts:[],
        fixedShiftsClock:[],
        fixedShiftsNoClock:[],
        noSchedulingClock:0,
        fixedShiftsScheduling:[],
        attendanceClockMethodsWifi:[],
        attendanceClockMethodsAddress:[],
        attendanceClockMethodsBluetooth:[],
        clockVerify:true,//打卡方式表单验证临时字段
      }
    }
  },
  created(){
    let query = {pageSize:20};
    selectRepairCardPage(query).then(val=>{
      if (val.status == 200){
        this.repairCardList=val.data.list;
      }else {
        this.$message.error('补卡规则获取失败！');
      }
    })
    selectDeptAndUserTree().then(val=>{
      if (val.status == 200){
        this.deptUserTree=val.data;
      }
    })
  }
}
</script>

<style scoped>
.set-button {
  background-color: white;
  width: 210px;
  border: #d8dbe2 1px solid;
  border-radius: 8px;
  height: 40px;
  line-height: 40px;
  padding: 0 12px;
  color: #c1c1c1;
  text-align: left;
  display: flex;
  align-items: center;
}
.set-button:hover {
  border: #c0c4cc 1px solid;
  display: flex;
  align-items: center;
}
.day-off{
  background-color: #FAFCFF;
  padding: 16px 0 0 20px;
  border-radius: 16px;
}
</style>
<style>
.el-cascader__tags {
  display: inline-flex;
  margin-right: 45px;
  flex-wrap: nowrap;
}

</style>