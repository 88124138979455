<template>
  <div>
    <div style="margin-bottom: 20px">
      <el-button type="danger" @click="createdItem">新 增</el-button>
      <el-input v-model="tableQuery.name" style="width: 200px;margin: 0 10px" placeholder="请输入名称"></el-input>
      <el-button :loading="tableLoading" type="danger" @click="selectAttendanceGroupByPage(0)">搜 索</el-button>
    </div>
    <el-table
        :data="tableData" class="table" height="626px" border v-loading="tableLoading">
      <el-table-column prop="id" label="ID" width="140"/>
      <el-table-column prop="name" label="名称" width="240"/>
      <el-table-column prop="attendanceType" label="类型" width="160" :formatter="attendanceType"/>
      <el-table-column prop="attendanceGroupPeopleSize" label="人数" width="140"/>
      <el-table-column prop="fixedShiftStr" show-overflow-tooltip label="考勤时间"/>
      <el-table-column prop="addTime" label="创建时间" width="220"/>
      <el-table-column label="操作" width="110" align="center">
        <template #default="scope">
          <div style="margin-right: 4px;">
            <el-button type="text" size="small" style="margin-right: 10px" @click.prevent="updateItem(scope.row)">编辑</el-button>
            <el-popconfirm title="确定要删除该班次吗？" @confirm="deleteItem(scope.row.id)">
              <el-button slot="reference" type="text" size="small" style="color: red;">删除</el-button>
            </el-popconfirm>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
        style="text-align: right;margin: 20px 10px"
        :page-sizes="[100, 200, 300, 400]"
        :page-size="10"
        @current-change="changePage"
        layout="total, prev, pager, next, jumper"
        :total="tableTotal">
    </el-pagination>
    <created-attendance-group ref="created" @refresh="selectAttendanceGroupByPage(0)"/>
    <update-attendance-group ref="update" @refresh="selectAttendanceGroupByPage(0)"/>
  </div>
</template>

<script>

import {selectAttendanceGroupByPage,deleteAttendanceGroup} from "../../../../api/attendance/attendanceGroup";
import { attendanceType } from "/src/utils/constant/stateConstant";
import CreatedAttendanceGroup from "./CreatedAttendanceGroup";
import UpdateAttendanceGroup from "./UpdateAttendanceGroup";
export default {
  name: "AttendanceGroup",
  components:{
    CreatedAttendanceGroup,UpdateAttendanceGroup
  },
  data() {
    return {
      tableQuery:{
        name:"",
        pageIndex:0,
        pageSize:10,
      },
      tableTotal:0,
      tableData:[],
      tableLoading:false,
    }
  },
  methods:{
    changePeople(){
      let list = [];
      const ref = this.$refs.cascaderRef;
      const checkedNodes = ref.getCheckedNodes();
      checkedNodes.forEach((node) => {
        list.push({coachId: node.value,coachName:node.label})
      });
      this.attendanceGroup.attendanceGroupPeople = list;
    },
    changePage(index){
      this.tableQuery.pageIndex = index;
    },
    attendanceType,
    createdItem(){
      this.$refs.created.openDialog();
    },
    updateItem(item){
      this.$refs.update.openDialog(item);
    },
    deleteItem(id){
      deleteAttendanceGroup(id).then(val => {
        if (val.status){
          this.selectAttendanceGroupByPage();
          this.$message({message: '删除成功！', type: 'success'});
        }else {
          this.$message.error('删除失败！');
        }
      })
    },
    selectAttendanceGroupByPage(index){
      this.tableLoading = true;
      this.tableQuery.pageIndex = index ? index:this.tableQuery.pageIndex;
      selectAttendanceGroupByPage(this.tableQuery).then(val => {
        this.tableLoading = false;
        if (val.status == 200){
          this.tableData = val.data.list;
          this.tableTotal = val.data.total;
        }
      })
    },
  },
  created() {
    this.selectAttendanceGroupByPage();
  }
}
</script>

<style scoped>
.table{
  width: 100%;
  border-radius: 10px;
  padding: 6px 0px 10px 6px;
}

</style>