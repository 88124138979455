import request from '/src/api/request.js'

/**
 *  分页查询班次
 */
export function selectClassesPage(query) {
    return request({
        url: 'sys/classes/GetClassesByPage',
        method: 'POST',
        data: query,
    })
}

/**
 * 添加班次
 */
export function createdClasses(item) {
    return request({
        url: 'sys/classes/insertClasses',
        method: 'POST',
        data: item,
    })
}

/**
 * 更新班次
 */
export function updateClasses(item) {
    return request({
        url: 'sys/classes/updateClasses',
        method: 'POST',
        data: item,
    })
}

/**
 * 删除班次
 */
export function deleteClasses(classesId) {
    return request({
        url: 'sys/classes/deleteClasses',
        method: 'POST',
        dataType: "json",
        headers:{
            'Content-Type':'application/json;charset=UTF-8',
        },
        data: classesId,
    })
}


/**
 *  获取所有班次
 */
export function selectClassesAll() {
    return request({
        url: 'sys/classes/GetClassesAll',
        method: 'POST',
        dataType: "json",
        headers:{
            'Content-Type':'application/json;charset=UTF-8',
        },
        data: {},
    })
}
