/**
 * 考勤组 - 考勤类型 - 开始
 */
export function attendanceType(row, column) {
    let key =row[column.property];
    const list = attendanceTypeList();
    return selectValueById(list,key).name;
}
export function attendanceTypeName(targetId) {
    const list = attendanceTypeList();
    return selectValueById(list,targetId).name;
}
export function attendanceTypeList() {
    const list = [{id:0,name:"固定班制"},{id:1,name:"排班制"}];
    return list;
}
/**
 * 考勤组 - 考勤类型 - 结束
 */



function selectValueById(list,targetId) {
    return list.find(item => item.id === targetId);
}