<template>
  <div style="height: 500px;overflow: auto">
    <el-form label-position="top" :model="clockType" ref="formRef" label-width="110px" class="demo-ruleForm">
      <div class="add-classes">
        <el-button @click="addAddress()" size="mini" type="danger">添加地址</el-button>
      </div>
      <el-table size="mini"
                :data="clockType.attendanceClockMethodsAddress"
                border height="310"
                style="border-radius: 10px;">
        <el-table-column label="名称"   width="230">
          <template #default="scope">
            <el-form-item :prop="'attendanceClockMethodsAddress[' + scope.$index + '].name'"
                          :rules="{required: true, message: '请选择时间', trigger: 'blur'}" style="margin: 0">
              <el-input size="mini" v-model="scope.row.name" placeholder="请输入"></el-input>
            </el-form-item>
          </template>
        </el-table-column>
        <el-table-column prop="name" label="考勤地址">
          <template #default="scope">
            <div style="display: flex;font-size: 10px;justify-content: space-between">
              <el-form-item :prop="'attendanceClockMethodsAddress[' + scope.$index + '].longitude'"
                            :rules="{required: true, message: '请选择时间', trigger: 'blur'}" style="margin: 0">
                <div>经度：<el-input style="width: 150px" size="mini" v-model="scope.row.longitude" placeholder="请输入"></el-input></div>
              </el-form-item>
              <div>
                <el-form-item :prop="'attendanceClockMethodsAddress[' + scope.$index + '].latitude'"
                              :rules="{required: true, message: '请选择时间', trigger: 'blur'}" style="margin: 0">
                  <div>维度：<el-input style="width: 150px" size="mini" v-model="scope.row.latitude" placeholder="请输入"></el-input></div>
                </el-form-item>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="有效范围"   width="130">
          <template #default="scope">
            <el-form-item :prop="'attendanceClockMethodsAddress[' + scope.$index + '].clockRange'"
                          :rules="{required: true, message: '请选择时间', trigger: 'blur'}" style="margin: 0">
              <el-select v-model="scope.row.clockRange" filterable placeholder="请选择" size="mini" style="width: 100%">
                <el-option v-for="item in 30" :key="item*100" :label="item*100" :value="item*100">{{item*100}}米</el-option>
              </el-select>
            </el-form-item>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="60" align="center">
          <template #default="scope">
            <div style="margin-right: 4px;">
              <el-popconfirm title="确定要删除吗？" @confirm="deleteAddress(scope.$index)">
                <el-button slot="reference" type="text" style="color: red;" class="el-icon-delete"></el-button>
              </el-popconfirm>
            </div>
          </template>
        </el-table-column>
      </el-table>
        <div style="margin: 26px 0 10px 0">
          <el-button @click="addWiFi()" size="mini" type="danger">添加WIFI</el-button>
        </div>
        <el-table size="mini"
                  :data="clockType.attendanceClockMethodsWifi"
                  border height="310"
                  style="border-radius: 10px;height: 300px">
          <el-table-column prop="name" label="WIFI名称">
            <template #default="scope">
              <el-form-item :prop="'attendanceClockMethodsWifi[' + scope.$index + '].name'"
                            :rules="{required: true, message: '请选择时间', trigger: 'blur'}" style="margin: 0">
                <el-input size="mini" v-model="scope.row.name" placeholder="请输入"></el-input>
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column label="MAC地址"  width="240">
            <template #default="scope">
              <el-form-item :prop="'attendanceClockMethodsWifi[' + scope.$index + '].address'"
                            :rules="{required: true, message: '请选择时间', trigger: 'blur'}" style="margin: 0">
                <el-input size="mini" v-model="scope.row.address" placeholder="请输入"></el-input>
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="60" align="center">
            <template #default="scope">
              <div style="margin-right: 4px;">
                <el-popconfirm title="确定要删除吗？" @confirm="deleteWiFi(scope.$index)">
                  <el-button slot="reference" type="text" style="color: red;" class="el-icon-delete"></el-button>
                </el-popconfirm>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div style="margin: 26px 0 10px 0">
          <el-button @click="addBluetooth()" size="mini" type="danger">添加蓝牙</el-button>
        </div>
        <el-table size="mini"
                  :data="clockType.attendanceClockMethodsBluetooth"
                  border height="310"
                  style="border-radius: 10px;">
          <el-table-column prop="name" label="蓝牙设备">
            <template #default="scope">
              <el-form-item :prop="'attendanceClockMethodsBluetooth[' + scope.$index + '].bluetoothId'"
                            :rules="{required: true, message: '请选择时间', trigger: 'blur'}" style="margin: 0">
                <el-select v-model="scope.row.bluetoothId" filterable placeholder="休息" size="mini" style="width: 100%">
                  <el-option v-for="item in BluetoothList" :key="item.id" :label="item.name" :value="item.id">
                    <span style="float: left">{{ item.name }}</span>
                    <span style="float: right; color: #C0C4CC; font-size: 13px">SN码：{{ item.snCode }}</span>
                  </el-option>
                </el-select>
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="60" align="center">
            <template #default="scope">
              <div style="margin-right: 4px;">
                <el-popconfirm title="确定要删除吗？" @confirm="deleteBluetooth(scope.$index)">
                  <el-button slot="reference" type="text" style="color: red;" class="el-icon-delete"></el-button>
                </el-popconfirm>
              </div>
            </template>
          </el-table-column>
        </el-table>
    </el-form>
  </div>
</template>

<script>
import {getBluetoothByPage} from '/src/api/attendance/attendanceGroup';

export default {
  name: "ClockType",
  data() {
    return {
      BluetoothList:[],
      clockType:{
        attendanceClockMethodsAddress:[],
        attendanceClockMethodsBluetooth:[],
        attendanceClockMethodsWifi:[]
      }
    }
  },
  methods:{
    addWiFi(){
      let item = {address:null,name:""};
      this.clockType.attendanceClockMethodsWifi.unshift(item);
    },
    addAddress(){
      let item = {latitude:null,longitude:null,clockRange:null,name:""};
      this.clockType.attendanceClockMethodsAddress.unshift(item);
    },
    addBluetooth(){
      let item = {bluetoothId:null};
      this.clockType.attendanceClockMethodsBluetooth.unshift(item);
    },
    deleteWiFi(index){
      this.clockType.attendanceClockMethodsWifi.splice(index, 1);
    },
    deleteAddress(index){
      this.clockType.attendanceClockMethodsAddress.splice(index, 1);
    },
    deleteBluetooth(index){
      this.clockType.attendanceClockMethodsBluetooth.splice(index, 1);
    },
    setItem(item){
      this.clockType.attendanceClockMethodsWifi = item.attendanceClockMethodsWifi;
      this.clockType.attendanceClockMethodsAddress = item.attendanceClockMethodsAddress;
      this.clockType.attendanceClockMethodsBluetooth = item.attendanceClockMethodsBluetooth;
    }
  },
  created() {
    this.$emit('getInitialData');
    let query = {pageSize:100};
    getBluetoothByPage(query).then(val=>{
      if (val.status){
        this.BluetoothList = val.data.list;
      }
    })
  }
}
</script>

<style scoped>
.add-classes{
  margin-bottom: 10px;
}
</style>