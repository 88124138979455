<template>
  <div>
    <el-form label-position="top" :model="attendanceTime" :rules="rules" ref="formRef" label-width="110px">
      <el-tabs style="margin-top: -30px" stretch v-model="attendanceTime.attendanceType">
        <el-tab-pane label="固定班制" name="0" style="height: 450px;overflow: auto">
          <div style="font-size: 14px;font-weight: bold">考勤设置</div>
            <div style="display: flex;margin: 10px 0 22px 0">
              <el-table size="mini" border :data="fixedShiftsFront" style="border-radius: 10px 0 0 10px">
                <el-table-column prop="weekdayStr" label="工作日" width="120"/>
                <el-table-column prop="name" label="班次时间段">
                  <template #default="scope">
                    <el-select v-model="scope.row.classesId" filterable placeholder="休息" size="mini" style="width: 100%" clearable >
                      <el-option v-for="item in classesList" :key="item.id" :label="item.name" :value="item.id">
                        <el-tooltip class="item" effect="dark" placement="top">
                          <div slot="content" style="text-align: center;width: 100%">
                            <div v-for="(detail, index) in item.classesDetails" :key="index">
                              <div style="margin-right: 20px;display: flex;align-items: center">
                                <div class="is-clock-in" style="margin-right: 4px" v-if="detail.toClock == 1"></div>
                                {{detail.toTime}} ~
                                <div class="is-clock-in"  style="margin:0 4px" v-if="detail.offClock == 1"></div>
                                {{detail.offTime}}
                              </div>
                            </div>
                          </div>
                          <div>{{ item.name }}</div>
                        </el-tooltip>
                      </el-option>
                    </el-select>
                  </template>
                </el-table-column>
              </el-table>
              <el-table size="mini" border
                        :data="fixedShiftsQueen"
                        style="border-radius: 0 10px 10px 0">
                <el-table-column prop="weekdayStr" label="工作日" width="120"/>
                <el-table-column prop="name" label="班次时间段">
                  <template #default="scope">
                    <el-select v-model="scope.row.classesId" filterable placeholder="休息" size="mini" style="width: 100%" clearable >
                      <el-option v-for="item in classesList" :key="item.id" :label="item.name" :value="item.id">
                        <el-tooltip class="item" effect="dark" placement="top">
                          <div slot="content" style="text-align: center;width: 100%">
                            <div v-for="(detail, index) in item.classesDetails" :key="index">
                              <div style="margin-right: 20px;display: flex;align-items: center">
                                <div class="is-clock-in" style="margin-right: 4px" v-if="detail.toClock == 1"></div>
                                {{detail.toTime}} ~
                                <div class="is-clock-in"  style="margin:0 4px" v-if="detail.offClock == 1"></div>
                                {{detail.offTime}}
                              </div>
                            </div>
                          </div>
                          <div>{{ item.name }}</div>
                        </el-tooltip>
                      </el-option>
                    </el-select>
                  </template>
                </el-table-column>
              </el-table>
            </div>
            <div style="display: flex;">
              <div style="width: 49%">
                <el-table size="mini"
                          :data="attendanceTime.fixedShiftsClock"
                          border
                          style="border-radius: 10px;">
                  <el-table-column label="必须打卡日期"  width="146">
                    <template #default="scope">
                      <el-form-item :prop="'fixedShiftsClock[' + scope.$index + '].weekDate'"
                                    :rules="{required: true, message: '请选择时间', trigger: 'blur'}" style="margin: 0">
                        <el-date-picker size="mini" style="width: 124px" value-format="yyyy-MM-dd" v-model="scope.row.weekDate" type="date" placeholder="选择日期"/>
                      </el-form-item>
                    </template>
                  </el-table-column>
                  <el-table-column prop="name" label="班次时间段">
                    <template #default="scope">
                      <el-form-item :prop="'fixedShiftsClock[' + scope.$index + '].classesId'"
                                    :rules="{required: true, message: '请选择时间', trigger: 'blur'}" style="margin: 0">
                        <el-select v-model="scope.row.classesId" filterable placeholder="休息" size="mini" style="width: 100%">
                          <el-option v-for="item in classesList" :key="item.id" :label="item.name" :value="item.id">
                            <el-tooltip class="item" effect="dark" placement="top">
                              <div slot="content" style="text-align: center;width: 100%">
                                <div v-for="(detail, index) in item.classesDetails" :key="index">
                                  <div style="margin-right: 20px;display: flex;align-items: center">
                                    <div class="is-clock-in" style="margin-right: 4px" v-if="detail.toClock == 1"></div>
                                    {{detail.toTime}} ~
                                    <div class="is-clock-in"  style="margin:0 4px" v-if="detail.offClock == 1"></div>
                                    {{detail.offTime}}
                                  </div>
                                </div>
                              </div>
                              <div>{{ item.name }}</div>
                            </el-tooltip>
                          </el-option>
                        </el-select>
                      </el-form-item>
                    </template>
                  </el-table-column>
                  <el-table-column label="操作" width="60" align="center">
                    <template #default="scope">
                      <div style="margin-right: 4px;">
                        <el-popconfirm title="确定要删除吗？" @confirm="deleteClock(scope.row.id)">
                          <el-button slot="reference" type="text" style="color: red;" class="el-icon-delete"></el-button>
                        </el-popconfirm>
                      </div>
                    </template>
                  </el-table-column>
                </el-table>
                <div class="add-classes">
                  <el-button @click="addClock()" size="mini" type="danger">添 加</el-button>
                </div>
              </div>
              <div style="width: 2%"></div>
              <div style="width: 49%">
                <el-table size="mini"
                          :data="attendanceTime.fixedShiftsNoClock"
                          border
                          style="border-radius: 10px;">
                  <el-table-column prop="weekDate" label="无需打卡日期"  width="146">
                    <template #default="scope">
                      <el-form-item :prop="'fixedShiftsNoClock[' + scope.$index + '].weekDate'"
                                    :rules="{required: true, message: '请选择时间', trigger: 'blur'}" style="margin: 0">
                        <el-date-picker size="mini" style="width: 124px" value-format="yyyy-MM-dd" v-model="scope.row.weekDate" type="date" placeholder="选择日期"/>
                      </el-form-item>
                    </template>
                  </el-table-column>
                  <el-table-column prop="classesName" label="班次时间段"/>
                  <el-table-column label="操作" width="60" align="center">
                    <template #default="scope">
                      <div style="margin-right: 4px;">
                        <el-popconfirm title="确定要删除吗？" @confirm="deleteNoClock(scope.$index)">
                          <el-button slot="reference" type="text" style="color: red;" class="el-icon-delete"></el-button>
                        </el-popconfirm>
                      </div>
                    </template>
                  </el-table-column>
                </el-table>
                <div class="add-classes">
                  <el-button @click="addNoClock()" size="mini" type="danger">添 加</el-button>
                </div>
              </div>
            </div>
        </el-tab-pane>
        <el-tab-pane label="排班制" name="1" style="height: 450px;overflow: auto">
          <el-form label-position="top" :model="attendanceTime" :rules="rules" ref="formRef" label-width="110px" class="demo-ruleForm">
            <el-row>
              <el-col :span="8">
                <el-form-item label-width="100px" label="未排班打卡规则" prop="attendanceType">
                  <div class="set-button">
                    <el-checkbox v-model="attendanceTime.noSchedulingClock" :true-label="1" :false-label="0">未排班时，员工可打卡</el-checkbox>
                  </div>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col>
                <el-form-item label-width="100px" label="考勤班次" prop="attendanceType">
                  <el-table  ref="multipleTable"
                    :data="classesList"  @selection-change="changeClasses"
                    tooltip-effect="dark" size="mini" height="260"
                    style="width: 98%;margin: 0 10px;border-radius: 10px;" border>
                  <el-table-column align="center" type="selection" width="55"/>
                  <el-table-column prop="classesId" label="Id" width="120"/>
                  <el-table-column
                      prop="name"
                      label="名称"
                      width="240">
                  </el-table-column>
                    <el-table-column label="考勤时间" min-width="300" align="center">
                      <template #default="scope">
                        <div style="display: flex;">
                          <div v-for="(item, index) in scope.row.classesDetails" :key="index">
                            <el-popover placement="top" width="260" trigger="hover">
                              <div class="to">
                                <div style="text-align: center;font-weight: bold">上班</div>
                                <div style="margin: 4px 0 2px 0;display: flex;justify-content: space-between">
                                  <div style="width: 50%">上班时间：{{item.toTime}}
                                    <el-checkbox style="margin-left: 4px" size="mini" :true-label="1" :false-label="0" v-model="item.toClock"></el-checkbox>
                                  </div>
                                </div>
                                <div>打卡时段：上班前{{item.startToTime}}分钟 <span class="el-icon-minus"></span> 上班后{{item.endToTime}}分钟</div>
                              </div>
                              <div class="off">
                                <div style="text-align: center;font-weight: bold">下班</div>
                                <div style="margin: 4px 0 2px 0;display: flex;justify-content: space-between">
                                  <div style="width: 50%">下班时间：{{item.offTime}}
                                    <el-checkbox style="margin-left: 4px" size="mini" :true-label="1" :false-label="0" v-model="item.offClock"></el-checkbox>
                                  </div>
                                </div>
                                <div>打卡时段：下班前{{item.startOffTime}}分钟 <span class="el-icon-minus"></span>  下班后{{item.endOffTime}}分钟</div>
                              </div>
                              <template #reference>
                                <el-tag type="danger" style="margin-right: 20px;display: flex;align-items: center">
                                  <div class="is-clock-in" style="margin-right: 4px" v-if="item.toClock == 1"></div>
                                  {{item.toTime}} ~
                                  <div class="is-clock-in"  style="margin:0 4px 0 6px" v-if="item.offClock == 1"></div>
                                  {{item.offTime}}
                                </el-tag>
                              </template>
                            </el-popover>
                          </div>
                        </div>
                      </template>
                    </el-table-column>
                </el-table>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </el-tab-pane>
      </el-tabs>
    </el-form>
  </div>
</template>

<script>
import { attendanceTypeList } from "/src/utils/constant/stateConstant";
import { selectClassesAll } from "/src/api/attendance/classes";

export default {
  name: "AttendanceTime",
  data() {
    return {
      value1:null,
      classesList:[],
      attendanceTime:{
        attendanceType:null,
        noSchedulingClock:0,
        fixedShifts:[
          {classesId: null, weekdayStr: "周一"},
          {classesId: null, weekdayStr: "周二"},
          {classesId: null, weekdayStr: "周三"},
          {classesId: null, weekdayStr: "周四"},
          {classesId: null, weekdayStr: "周五"},
          {classesId: null, weekdayStr: "周六"},
          {classesId: null, weekdayStr: "周日"},
        ],
        fixedShiftsClock:[],
        fixedShiftsNoClock:[],
        fixedShiftsScheduling:[]
      },
      rules: {
        attendanceType: [{required: true, message: "请选择考勤类型！", trigger: "blur"}],
      }
    }
  },
  computed: {
    fixedShiftsFront() {
      let list = this.attendanceTime.fixedShifts;
      return [list[0],list[2],list[4],list[6]];
    },
    fixedShiftsQueen() {
      let list = this.attendanceTime.fixedShifts;
      return [list[1],list[3],list[5]];
    },
  },
  methods:{
    changeClasses(val){
      this.attendanceTime.fixedShiftsScheduling = val;
    },
    attendanceTypeList,
    deleteClock(index){
      this.attendanceTime.fixedShiftsClock.splice(index, 1);
    },
    deleteNoClock(index){
      this.attendanceTime.fixedShiftsNoClock.splice(index, 1);
    },
    addClock(){
      this.attendanceTime.fixedShiftsClock.push({classesId: null, weekDate: null});
    },
    addNoClock(){
      this.attendanceTime.fixedShiftsNoClock.push({classesName: "休息", weekDate: null});
    },
    setItem(item){
      if (item.fixedShifts != null && item.fixedShifts.length>0){
        this.attendanceTime.fixedShifts = item.fixedShifts;
      }
      this.attendanceTime.attendanceType = item.attendanceType.toString();
      this.attendanceTime.fixedShiftsClock = item.fixedShiftsClock == null ? []:item.fixedShiftsClock;
      this.attendanceTime.noSchedulingClock = item.noSchedulingClock == null ? []:item.noSchedulingClock;
      this.attendanceTime.fixedShiftsNoClock = item.fixedShiftsNoClock == null ? []:item.fixedShiftsNoClock;
      this.attendanceTime.fixedShiftsScheduling = item.fixedShiftsScheduling == null ? []:item.fixedShiftsScheduling;
      this.toggleSelection();
    },
    toggleSelection() {
      this.attendanceTime.fixedShiftsScheduling.forEach(item => {
        const targetRow = this.classesList.find(row => row.classesId === item.classesId);
        if (targetRow) {
          this.$refs.multipleTable.toggleRowSelection(targetRow, true);
        }
      });
    }
  },
  created(){
    this.$emit('getInitialData');
    selectClassesAll().then(val=>{
      if (val.status){
        for (const item of val.data) {
          item.classesId = item.id;
        }
        this.classesList = val.data;
      }
    })
  }
}
</script>

<style scoped>
.add-classes{
  text-align: center;
  margin-top: 10px;
}
/deep/ .el-tabs__active-bar {
  background-color: #ff4d4f;
}
/**悬浮 */
::v-deep .el-tabs__item:hover {
  color: #ff4d4f;
  cursor: pointer;
  opacity: 1;
}
.table{
  width: 100%;
  border-radius: 10px;
  padding: 6px 10px 10px 10px;
  height: 550px;
}
.el-aside {
  text-align: center;
  padding: 10px 20px;
  margin: 8px 20px 0 0;
  border-radius: 10px;
  box-shadow: 0 0px 20px 0 rgba(94, 93, 93, 0.1)
}
.is-clock-in{
  height: 5px;width: 5px;background-color: #67C23A;border-radius: 50px;
}
.set-button {
  background-color: white;
  width: 210px;
  border: #d8dbe2 1px solid;
  border-radius: 8px;
  height: 40px;
  line-height: 40px;
  padding: 0 12px;
  color: #c1c1c1;
  text-align: left;
  display: flex;
  align-items: center;
}
.set-button:hover {
  border: #c0c4cc 1px solid;
  display: flex;
  align-items: center;
}
</style>